import * as React from "react"

import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import {
  MapContainer, TileLayer, Marker, Popup
} from 'react-leaflet'
import 'leaflet/dist/leaflet.css';



import markerIconPng from "leaflet/dist/images/marker-icon.png"
import {Icon} from 'leaflet'



const ContactPage = () => {
  const hasMounted = useHasMounted()

  const { titleStrip } = useStaticQuery(graphql`
    query {
      titleStrip: file(relativePath: { eq: "title-banner.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            formats: [AUTO, WEBP, AVIF]
            placeholder: BLURRED
            layout: FULL_WIDTH
          )
        }
      }
    }
  `)

  const titleStripBg = convertToBgImage(getImage(titleStrip))
 

  return (
    <Layout>
      <SEO title="Contact" />
      <BackgroundImage
        className="container-fluid title-banner"
        {...titleStripBg}
      >
        <h2 className="section-heading">Contact</h2>
      </BackgroundImage>
      <div className="container contact-form">
        <div className="row">
          <div className="col-md-6">
            <div className="info-enquiries">
              <h3>General Enquiries</h3>
              <p>
                Should you require any additional assistance or have any
                requests to make your stay more comfortable, please do not
                hesitate to contact us. We are also able to make any additional
                activity bookings on your behalf.
              </p>
            </div>
          </div>

          <div className="col-md-6">
            <div className="contact-page-info">
              <div className="col-md-6 left">
                <div className="contact-page-item-con">
                  <div className="contact-page-item">
                    <p>Address:</p>
                    <p>20 Haarlem Street</p>
                  </div>

                  <div className="contact-page-item">
                    <p>City:</p>
                    <p>De Kelders, Cape Town</p>
                  </div>
                </div>
              </div>

              <div className="col-md-6 right">
                <div className="contact-page-item-con">
                  <div className="contact-page-item">
                    <p>Whatsapp:</p>
                    <p>+27 82 380 4928</p>
                  </div>

                  <div className="contact-page-item">
                    <p> Tel:</p>
                    <p> +27 (0)28 384 0343</p>
                  </div>
                </div>
              </div>

              <div className="col-md-12 tel-no">
                {/* <h2>Email:</h2>
                <h2>info@marine5hotel.com</h2> */}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="map">
            <div>
              {hasMounted && (
                <MapContainer
                  center={[-34.56388378790483, 19.354359743166178]}
                  zoom={16}
                  style={{ height: "400px", width: "100%" }}
                >
                  <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  <Marker position={[-34.56388378790483, 19.354359743166178]} icon={new Icon({iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41]})}>
                    <Popup>
                      <a href="https://www.google.com/maps/dir//''/@-34.563864,19.3193193,13z/data=!4m8!4m7!1m0!1m5!1m1!1s0x1dce11d6aa5caf39:0x97341dd11dc0c398!2m2!1d19.3543824!2d-34.5638678">
                        Get Directions
                      </a>
                    </Popup>
                  </Marker>
                </MapContainer>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
  function useHasMounted() {
    const [hasMounted, setHasMounted] = React.useState(false)
    React.useEffect(() => {
      setHasMounted(true)
    }, [])
    return hasMounted
  }
}

export default ContactPage
